@font-face {
  font-family: 'DB Helvethaica X';
  src: url('assets/fonts/DB\ Helvethaica\ X\ Med\ v3.2.ttf');
  src: url('assets/fonts/DB\ Helvethaica\ X\ Bd\ Cond\ v3.2.ttf');
  src: url('assets/fonts/DB\ Helvethaica\ X\ Li\ v3.2.ttf');
  src: url('assets/fonts/DB\ Helvethaica\ X\ v3.2.ttf');
}

body {
  margin: 0;
  font-family: 'DB Helvethaica X';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('/src/assets/bg-ams.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  width: 100%;
}

.MuiTypography-h3 {
  font-size: 64px;
  line-height: 70px;
}

.MuiTypography-h4 {
  font-size: 40px;
  line-height: 40px;
}

.MuiTypography-h6 {
  font-size: 32px;
}

.MuiTypography-subtitle2 {
  font-size: 32px;
}

.MuiTypography-body1 {
  font-size: 24px;
}

.MuiCard-root {
  background-color: rgba(255, 255, 255, 0.5);
  color: #0e3688;
}

@media only screen and (max-width: 600px) {
  .MuiTypography-body1 {
    font-size: 4.5vw;
  }

  .MuiTypography-h4 {
    font-size: 7.5vw;
    line-height: 7.7vw;
  }

  .MuiTypography-h3 {
    font-size: 9.5vw;
    line-height: 10vw;
  }
}

.button-select-species > p,
.button-select-times > p,
.button-select-locations > p,
.button-select-confidences > p {
  font-size: 32px;
  font-weight: 700;
}

.button-select-date > span {
  font-size: 32px;
  font-weight: 700;
}

ul.list-classified > li::marker {
  font-size: 26px;
  line-height: 18px;
}

.box-left {
  border-right: 1px solid #2aaae2;
}

@media (min-width: 600px) and (max-width: 899px) {
  .box-left {
    border-right: 0px solid #2aaae2;
    border-bottom: 1px solid #2aaae2;
  }
}

@media (max-width: 599px) {
  .box-left {
    border-right: 0px solid #2aaae2;
    border-bottom: 1px solid #2aaae2;
  }
  .icon-box {
    width: 64px;
    height: 64px;
  }
  .box-value {
    font-size: 48px !important;
    line-height: 56px;
  }
}
