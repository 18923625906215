.rdrStartEdge {
    background: linear-gradient(90deg, white 50%, rgba(24, 144, 255, 0.08) 50%) !important;
}

.rdrEndEdge {
    background: linear-gradient(90deg, rgba(24, 144, 255, 0.08) 50%, white 50%) !important;
}

.rdrDayNumber span {
    color: #637381 !important;
}

.rdrDayPassive .rdrDayNumber span {
    color: #919EAB !important;
}

.rdrDayNumber .dateDataActive {
    color: #212B36 !important;
    font-weight: bold;
}

.rdrStartEdge ~ .rdrDayNumber > div > span {
    color: white !important;
    font-weight: bold;
}

.rdrStartEdge ~ .rdrDayNumber > div {
    background: #2AAAE2 !important;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 2px;
    text-align: center;
    line-height: 35px;
    text-align: center;
}

.rdrEndEdge ~ .rdrDayNumber > div > span {
    color: white !important;
    font-weight: bold;
}

.rdrEndEdge ~ .rdrDayNumber > div {
    background: #2AAAE2 !important;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 2px;
    text-align: center;
    line-height: 35px;
    text-align: center;
}